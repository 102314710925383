<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Dodawanie nowego zleceniodawcy
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="mb-4 w-2/3 px-1">
          <label>nazwa</label>
          <input :class="{ 'border-red-300': errors.name !== undefined }" v-model="form.name" type="text">
          <p v-if="errors.name !== undefined" class="text-red-500 text-sm">{{ errors.name }}</p>
        </div>
        <div class="mb-4 w-1/3 px-1">
          <label>nazwa skrócona</label>
          <input :class="{ 'border-red-300': errors.shortname !== undefined }" v-model="form.shortname" type="text">
          <p v-if="errors.shortname !== undefined" class="text-red-500 text-sm">{{ errors.shortname }}</p>
        </div>
        <div class="mb-4 w-11/12 md:w-5/12 px-1">
          <label>telefon</label>
          <input :class="{ 'border-red-300': errors.phone !== undefined }" v-model="form.phone" type="text">
          <p v-if="errors.phone !== undefined" class="text-red-500 text-sm">{{ errors.phone }}</p>
        </div>
        <div class="mb-4 w-1/12 px-1 text-center">
          <label>PL</label>
          <input v-model="form.regional_phone" true-value="1" false-value="0" type="checkbox">
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>email kontakt.</label>
          <input :class="{ 'border-red-300': errors.email !== undefined }" v-model="form.email" type="email">
          <p v-if="errors.email !== undefined" class="text-red-500 text-sm">{{ errors.email }}</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import ContractorService from '../services/ContractorService';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'ContractorAdd',
  mixins: [errorHandler],
  data() {
    return {
      form: {
        name: '',
        shortname: '',
        email: '',
        phone: '',
        regional_phone: '1',
      },
      errors: {},
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    save() {
      ContractorService.addContractor(this.form)
        .then(response => {
          const newContractor = response.data.result;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zleceniodawca został dodany...',
          });
          this.$emit('changed', newContractor);
          this.show = false;
          this.$router.push({ name: 'contractor', params: { id: newContractor.id }, query: this.$route.query });
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
  },
};
</script>
